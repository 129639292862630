import type { GraphQLError } from "graphql"

// カスタムエラークラスの定義
class BaseError extends Error {
  constructor(e?: string) {
    super(e)
    this.name = new.target.name
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class FetchError extends BaseError {
  constructor(
    public statusCode: number,
    e?: string
  ) {
    super(e)
  }
}

export class GraphQLExecutionResultError extends BaseError {
  constructor(
    public statusCode: number,
    public readonly errors: readonly GraphQLError[],
    e?: string
  ) {
    super(e)
  }
}

export enum ERROR_CODE {
  // ページが見つからない
  NOT_FOUND = "NOT_FOUND",
}

export function isGraphQLError(
  error: GraphQLExecutionResultError,
  code: ERROR_CODE
) {
  return error.errors.some((e) => e.extensions?.code === code)
}
